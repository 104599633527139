* {
    margin: 0;
    padding: 0;

    font-family: 'Helvetica', 'Arial', sans-serif;
}

html, body, #root {
    width: 100%;
    height: 100%;
}


canvas {
    width: 100%;
    height: 100%;
    overflow: hidden;

    /*background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);*/
}


.name {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min-content;

    color: rgba(136, 159, 203, 0.6);

    font-size: 8vmin;
    cursor: pointer;
}

.y2020 {
    transform: rotate(90deg);
    top: calc(50% - 16vmin);
    color: rgba(136, 159, 203, 0.2);
}